import { KJUR } from 'jsrsasign';
import '@zoom/videosdk-ui-toolkit/dist/videosdk-ui-toolkit.css'
import uitoolkit from '@zoom/videosdk-ui-toolkit'
import VConsole from 'vconsole';
const vConsole = new VConsole();


function generateSignature(sdkKey, sdkSecret, sessionName, role, sessionKey, userIdentity) {

  const iat = Math.round(new Date().getTime() / 1000) - 30
  const exp = iat + 60 * 60 * 2
  const oHeader = { alg: 'HS256', typ: 'JWT' }

  const oPayload = {
    app_key: sdkKey,
    tpc: sessionName,
    role_type: parseInt(role),
    session_key: sessionKey,
    user_identity: userIdentity,
    version: 1,
    iat: iat,
    exp: exp
  }

  const sHeader = JSON.stringify(oHeader)
  const sPayload = JSON.stringify(oPayload)
  const sdkJWT = KJUR.jws.JWS.sign('HS256', sHeader, sPayload, sdkSecret)
  return sdkJWT
}

document.addEventListener('DOMContentLoaded', async e => {
  var previewContainer = document.getElementById('previewContainer')

  const currentUrl = window.location.href;

// Создаем объект URLSearchParams на основе параметров URL
  const urlObject = new URL(currentUrl);
  const role = urlObject.searchParams.get('role'); // 0 - host, 1 - guest
  const name = urlObject.searchParams.get('name'); // Anton
  const user = urlObject.searchParams.get('user'); // 'user123'
  const key = urlObject.searchParams.get('key'); // 'session123'

  console.log(role, key, user, name)

  uitoolkit.openPreview(previewContainer);
  const token = generateSignature('jFgTmO_tQ_-Y43XErhkGTw', '070BL6WgJi6dqGI1W4gvLZ1FQ1i7m6Up6FnW', 'coolcars', role, key, user);

  var config = {
    videoSDKJWT: token,
    sessionName: 'coolcars',
    userName: name,
    sessionPasscode: 'abc123',
    features: ['video', 'audio', 'share', 'chat', 'users', 'settings']
  };

  var sessionContainer = document.getElementById('sessionContainer')

  uitoolkit.joinSession(sessionContainer, config)
});
